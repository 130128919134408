import React from "react"
import LandingPage from "../../Layout/LandingPage"
import { graphql } from "gatsby"
import { styled } from "@mui/material/styles"
import sectionStyle from "../Sections/sectionStyle"
import { SectionImages } from "./Components/SectionImages"
import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import { Link } from "gatsby"

export default ({ data }) => {
  const ThankYouPagesNvl4 = data?.allStrapiThankYouPagesNvl4.edges

  const panelPaginaGenerales = data.allStrapiPaginaGenerales.edges


  const codigoProducto = panelPaginaGenerales[0].node.nombre_producto
  const area = panelPaginaGenerales[0].node.nombre_area

  const nombreProducto = codigoProducto
    ?.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
  const nombreProductoSinEspacios = nombreProducto.replace(/\s+/g, "")
  const nombreArea = area?.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
  const urlToMeta = "PolizadeSeguros/" + nombreArea.trim()+"/"+ nombreProductoSinEspacios + "/" +"thankyoupage"
   const NewDiv = styled("div")(({ theme, ownerState }) => {
    const { style } = ownerState
    return { ...sectionStyle[style] }
  })

  const Article = styled("article")({
    h2: {
      margin: "0",
      fontSize: "1.5rem",
      fontWeight: "bold",
    },

    "@media (max-width: 600px)": {
      h2: {
        fontSize: "1.5rem",
      },
    },
  })
  const ContainerPages = styled("section")({
    margin: "130px auto 0 auto",
    // paddingTop: '2rem',
    position: "relative",
    zIndex: 3,
    color: "#7c7b7b",
    backgroundColor: "white",
    borderRadius: "6px",
    width: "60%",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    "@media (max-width: 1500px)": {
      width: "80%",
      marginTop: "8rem",

      // paddingRight:'100px',
      // paddingLeft:'100px',
    },
    "@media (max-width: 768px)": {
      width: "95%",
      marginTop: "7.5rem",

      // paddingRight:'20px',
      // paddingLeft:'20px',
    },
    "@media (max-width: 600px)": {
      marginTop: "0.7rem",

      width: "92%",
    },
  })

  return (
    <LandingPage pageTitle={" Productos "}urlToMeta={urlToMeta} >
      <div>
        <NewDiv
          ownerState={{
            style: "StyleThankYouPage",
          }}
        >
          <ContainerPages>
            <SectionImages ThankYouPagesNvl4={ThankYouPagesNvl4} />
            <div
              style={{
                with: "100%",
                display: "flex",
                justifyContent: "center",
                padding: "25px",
              }}
            >
              <Link to="/">
                <Button color="primary" type="submit">
                  <KeyboardBackspaceIcon />
                  Regresar
                </Button>
              </Link>
            </div>
          </ContainerPages>
        </NewDiv>
      </div>
    </LandingPage>
  )
}

export const query = graphql`
  query ($id: String!) {
    allStrapiThankYouPagesNvl4: allStrapiThankYouPageNvl4(
      filter: { codigo_producto: { codigo_producto: { eq: $id } } }
    ) {
      edges {
        node {
          titulo
          imagen {
            localFile {
              url
            }
          }
          texto {
            data {
              texto
            }
          }
        }
      }
    }
    allStrapiPaginaGenerales: allStrapiPaginaGeneral(
      filter: { codigo_producto: { eq: $id } }
    ) {
      edges {
        node {
          banner_boton_accion
          banner_formulario
          banner_generico
          nombre_producto
          codigo_producto
          nombre_area
        }
      }
    }
  }
`
