import React from "react"
import { styled } from "@mui/material"
import {
  COLOR_FONDO,
  TAMANIO_IMG_PANEL_INFORMATIVO,
  H2,
} from "../../SEO/constants/constants"
import { CleanText } from "../../SEO/Functions/CleanText"

export const SectionImages = ({ ThankYouPagesNvl4 }) => {
  const image = ThankYouPagesNvl4[0].node.imagen[0].localFile.url
  const titulo = ThankYouPagesNvl4[0].node.titulo
  const texto = ThankYouPagesNvl4[0].node.texto.data.texto
  console.log("VER VALOR", ThankYouPagesNvl4)

  const Div = styled("div")(() => ({
    display: "flex",
    // marginTop: "2rem",
    // marginBottom: "2rem",
    // flexDirection: isNormal ? 'row' : 'row-reverse',
    justifyContent: "center",
    // gap: "80px",
    paddingTop: "50px",
    img: {
      width: "100%",
      maxWidth: TAMANIO_IMG_PANEL_INFORMATIVO.width,
      maxHeight: TAMANIO_IMG_PANEL_INFORMATIVO.height,
      alignSelf: "center",
      objectFit: "contain",
    },
    // Pantallas medianas: 1024px o menos
    "@media (max-width: 1024px)": {

      display: "flex",
      flexDirection: "column",
      img: {
        alignSelf: "center", // Centrar la imagen si es más grande que el contenido
      },
    },
    "@media (max-width: 800px)": {
      display: "flex",
      flexDirection: "column",
      img: {
        alignSelf: "center", // Centrar la imagen si es más grande que el contenido
      },
      "@media (max-width: 600px)": {

        display: "flex",
        flexDirection: "column",
        img: {
          alignSelf: "center", // Centrar la imagen si es más grande que el contenido
        },
      },
    },
  }))
  const Article = styled("article")({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "30px",
    H2: {
      marginTop: "5rem",
      fontWeight: "bold",
      fontSize: "1.5rem",
      //   textAlign: "start",
    },
    p: {
      fontSize: "15px",
      textAlign: "center",
      margin: "0 0 0 25px",
    },
    "@media (max-width: 768px)": {
      // Ajustes para pantallas de hasta 768px (tablets pequeñas)
      width: "100%",
      H2: {
        margin: "0",
        fontSize: "1.2rem",
      },
      p: {
        fontSize: "1rem",
        margin: "0",
      },
    },

    "@media (max-width: 600px)": {
      // Ajustes para pantallas pequeñas (móviles)
      width: "100%",
      marginTop: "1rem",
      H2: {
        margin: "0",
        fontSize: "1rem",
      },
      p: {
        fontSize: "1rem",
        margin: "0",
      },
    },
  })

  return (
    <Div>
      <Article>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            padding: "0px 20px",
          }}
        >
          <H2>{titulo}</H2>
          <p>{CleanText(texto)}</p>
        </div>
      </Article>
      <img src={image} />
    </Div>
  )
}
